import { getBaseUrl } from './util.js';

export async function submitInjestUrl(url) {
  const body = JSON.stringify({ url });
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/queue/video-url`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then(data => data.text());
}
