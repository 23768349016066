import queueStatus from './queue-status';
import queueLengthHistory from './queue-length-history';
import latestItems from './latest-items';
import filesInfo from './files-info';
import fileRequestHistory from './file-request-history';

const state = {
  queueStatus: queueStatus.state,
  queueLengthHistory: queueLengthHistory.state,
  latestItems: latestItems.state,
  filesInfo: filesInfo.state,
  fileRequestHistory: fileRequestHistory.state
};

export default {
  state,
  queueStatus: queueStatus.methods,
  queueLengthHistory: queueLengthHistory.methods,
  latestItems: latestItems.methods,
  filesInfo: filesInfo.methods,
  fileRequestHistory: fileRequestHistory.methods
};
