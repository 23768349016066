import { reactive, readonly } from 'vue';
import * as libFilesInfo from '../lib/files-info';
let timeout;
let intervalId;

const state = reactive({
  files: []
});

const methods = {
  get: (fromSubscribe = false) => {
    if (!fromSubscribe && intervalId) {
      methods.subscribe(timeout);
      return;
    }
    libFilesInfo.get().then(result => {
      state.files = result;
    });
  },
  subscribe: (interval = 60000) => {
    if (intervalId) {
      clearTimeout(intervalId);
      intervalId = undefined;
    }
    timeout = interval;
    methods.get(true);
    intervalId = setInterval(() => {
      methods.get(true);
    }, interval);
  }
};

export default { state: readonly(state), methods };
