import { getBaseUrl, deliverMock } from './util.js';
import mock from './mock/queue-status.json';

const IS_MOCK = false;

export async function get() {
  if (IS_MOCK) {
    return deliverMock('queue-status', mock);
  }

  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/queue/status`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(data => data.json());
}
