<template>
  <div class="queue-length-history-chart">
    <div
      class="area-wrapper"
      :style="{
        marginTop: $props.dimensions.margin.top + 'px',
        marginLeft: $props.dimensions.margin.left + 'px',
        marginRight: $props.dimensions.margin.right + 'px',
        height:
          $props.dimensions.height -
          $props.dimensions.margin.top -
          $props.dimensions.margin.bottom +
          'px'
      }"
    >
      <div class="area">
        <div class="max-tick">
          <div class="max-tick-label-wrapper">
            <div class="max-tick-label" v-text="f(maxValue || '')" />
          </div>
        </div>
        <div class="min-tick">
          <div class="min-tick-label-wrapper">
            <div class="min-tick-label" v-text="0" />
          </div>
        </div>
        <div
          class="bar"
          v-for="item in internalData"
          :key="item"
          :style="{
            height: `${getBarHeight(item.length)}px`,
            width: '1px',
            backgroundColor:
              item.length === maxValue - 1
                ? 'rgb(18, 93, 133)'
                : 'rgba(18, 93, 133, 0.5)'
          }"
          :title="`${item.minute.substring(0, 16).replace('T', ' ')} - ${f(
            item.length
          )}`"
        />
      </div>
      <!-- x-axis -->
      <div class="area">
        <div
          class="x-tick"
          v-for="item in internalData"
          :key="item"
          :class="{ active: isFullHour(item.minute) }"
        >
          <div
            v-if="isFullHour(item.minute)"
            v-text="getHour(item.minute)"
            class="label"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['store'],
  props: {
    dimensions: {
      type: Object,
      default: () => ({
        height: 20,
        margin: { top: 0, right: 0, left: 0, bottom: 0 }
      })
    }
  },
  created() {
    this.store.queueLengthHistory.subscribe();
  },
  computed: {
    internalData() {
      return this.store.state.queueLengthHistory.history;
    },
    areaHeight() {
      const {
        height,
        margin: { top, bottom }
      } = this.$props.dimensions;
      return height - top - bottom;
    },
    maxValue() {
      return (
        this.internalData.reduce(
          (acc, item) => (item.length > acc ? item.length : acc),
          0
        ) + 1
      );
    }
  },
  methods: {
    getBarHeight(amount) {
      return (this.areaHeight * amount) / this.maxValue + 1;
    },
    isFullHour(timestamp = '') {
      return timestamp.substring(14, 16) === '00';
    },
    getHour(timestamp = '') {
      const d = new Date(timestamp);
      d.setHours(d.getHours() + 1);
      return d.toISOString().substring(11, 16);
    },
    f(value) {
      if (typeof value !== 'number') return value;
      return value.toLocaleString('de-DE');
    }
  }
};
</script>

<style lang="scss">
.queue-length-history-chart {
  width: 720px;
  position: absolute;
  top: 0px;
  left: 275px;

  .area-wrapper {
    .area {
      position: relative;
      display: flex;
      align-items: flex-end;
      .bar {
        background: rgba(18, 93, 133, 0.5);
      }
      .x-tick {
        position: relative;
        background: rgba(18, 93, 133, 0.5);
        background: none;
        width: 1px;
        height: 0;
        &.active {
          height: 3px;
        }

        .label {
          position: absolute;
          top: 3px;
          left: -12px;
          font-size: 10px;
          color: $pale;
        }
      }
      .max-tick {
        position: absolute;
        top: 0;
        left: -5px;
        height: 1px;
        width: 5px;

        .max-tick-label-wrapper {
          position: absolute;
          top: 0;
          left: -3px;
          width: 0;
          .max-tick-label {
            position: absolute;
            top: -4px;
            right: 0;
            text-align: right;
            font-size: 10px;
            line-height: 12px;
            color: $pale;
          }
        }
      }
      .min-tick {
        position: absolute;
        top: 20px;
        left: -5px;
        height: 1px;
        width: 5px;

        .min-tick-label-wrapper {
          position: absolute;
          top: 0;
          left: -3px;
          width: 0;
          .min-tick-label {
            position: absolute;
            top: -4px;
            right: 0;
            text-align: right;
            font-size: 10px;
            line-height: 12px;
            color: $pale;
          }
        }
      }
    }
  }
}
</style>
