<template>
  <div class="app-header">
    <div>
      <h1>
        <img class="lyra" src="@/assets/lyra.svg" alt="" />Apollo Dashboard
      </h1>
      <b>Media File Inspection Service</b>
    </div>
    <div class="logo">
      <img src="@/assets/71-logo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.app-header {
  position: relative;
  margin: 30px 0 20px 0;
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
  }
  .lyra {
    height: 32px;
    margin-right: 3px;
    margin-bottom: -4px;
  }
  .logo {
    margin-top: 0px;
    img {
      height: 40px;
    }
  }
}
</style>
