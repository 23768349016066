<template>
  <div class="file-request-history-chart">
    <div class="chart-title">DELIVERIES</div>
    <div class="mode-switch">
      <span @click="this.mode = 'day'" :class="{ active: this.mode === 'day' }"
        >day</span
      ><span
        @click="this.mode = 'hour'"
        :class="{ active: this.mode === 'hour' }"
        >hour</span
      >
    </div>
    <div
      class="area-wrapper"
      :style="{
        marginTop: $props.dimensions.margin.top + 'px',
        marginLeft: $props.dimensions.margin.left + 'px',
        marginRight: $props.dimensions.margin.right + 'px',
        height:
          $props.dimensions.height -
          $props.dimensions.margin.top -
          $props.dimensions.margin.bottom +
          'px'
      }"
    >
      <div class="area">
        <template v-if="mode === 'day'">
          <div
            class="bar"
            v-for="item in internalData"
            :key="item"
            :style="{
              height: `${getBarHeight(item.sum)}px`,
              width: '24px',
              marginRight: '1px'
            }"
            :title="`${item.day}: ${item.sum}`"
          ></div>
        </template>
        <template v-if="mode === 'hour'">
          <template v-for="item in internalData" :key="item">
            <div
              class="bar"
              v-for="(hourOfDay, index) in Array(24).fill(undefined)"
              :key="hourOfDay"
              :style="{
                height: `${getBarHeight(
                  item.hours.find(({ hour }) => parseInt(hour) === index)
                    ?.amount || 0
                )}px`,
                width: '1px'
              }"
              :title="`${item.day} ${index}: ${
                item.hours.find(({ hour }) => parseInt(hour) === index)
                  ?.amount || 0
              }`"
            ></div>
          </template>
        </template>
      </div>
      <div
        class="xAxis"
        :style="{ height: `${$props.dimensions.xAxisHeight}px` }"
      >
        <div
          class="bar"
          v-for="item in internalData"
          :key="item"
          style="width: 24px"
        >
          <div class="content" v-text="item.day"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dimensions: {
      type: Object,
      default: () => ({
        height: 200,
        margin: { top: 40, right: 50, left: 20, bottom: 20 },
        xAxisHeight: 60
      })
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mode: 'day'
    };
  },
  computed: {
    history() {
      return this.$props.data.map(item => {
        const { hour, amount } = item;
        return { hour, amount: parseInt(amount) || 0 };
      });
    },
    internalData() {
      if (!this.data.length) return [];

      const firstDay = new Date(this.history[0].hour.substring(0, 10));
      const lastDay = new Date(
        this.data[this.history.length - 1].hour.substring(0, 10)
      );
      let currentDay = firstDay;
      let result = [];
      while (currentDay <= lastDay) {
        const day = currentDay.toISOString().substring(0, 10);
        const sum = this.history
          .filter(({ hour }) => hour.startsWith(day))
          .reduce((acc, { amount = 0 }) => acc + amount, 0);
        result.push({
          day,
          sum,
          hours: this.history
            .filter(({ hour }) => hour.startsWith(day))
            .map(({ hour, amount }) => ({
              hour: hour.substring(11, 13),
              amount
            }))
        });
        currentDay.setDate(currentDay.getDate() + 1);
      }
      if (result.length > 35) {
        result = result.slice(result.length - 35);
      }

      return result;
    },
    maxValue() {
      if (this.mode === 'hour') {
        return (this.history || []).reduce((acc, item) => {
          return item.amount > acc ? item.amount : acc;
        }, 0);
      }
      return (this.internalData || []).reduce((acc, item) => {
        return item.sum > acc ? item.sum : acc;
      }, 0);
    },
    areaHeight() {
      const {
        height,
        margin: { top, bottom },
        xAxisHeight
      } = this.$props.dimensions;
      return height - top - bottom - xAxisHeight;
    }
  },
  methods: {
    getBarHeight(amount) {
      return (this.areaHeight * amount) / this.maxValue;
    }
  }
};
</script>

<style lang="scss">
.file-request-history-chart {
  min-width: 200px;
  border: 1px solid rgb(18, 93, 133, 0.5);
  position: absolute;
  bottom: 30px;
  right: 0;
  background: #f7f7f7;
  background: rgb(250, 250, 250, 0.85);
  backdrop-filter: blur(2px);

  .chart-title {
    position: absolute;
    top: 4px;
    left: 20px;
    padding-top: 6px;
    font-size: 12px;
    font-weight: bold;
    color: $accent;
  }

  .mode-switch {
    position: absolute;
    top: 4px;
    right: 20px;
    span {
      margin-left: 5px;
      font-size: 12px;
      cursor: pointer;
      &.active {
        color: $accent;
        cursor: auto;
        font-weight: bold;
      }
    }
  }

  .area {
    display: flex;
    align-items: flex-end;
    .bar {
      background: $accent;
    }
  }
  .xAxis {
    display: flex;
    gap: 1px;
    padding: 10px 0 0 4px;
    .content {
      transform: rotate(45deg);
      font-size: 12px;
      line-height: 12px;
      white-space: nowrap;
    }
  }
}
</style>
