<template>
  <div class="queue-status">
    <collapse>
      <template v-slot:title>
        <h2>Queue Status</h2>
      </template>
      <p class="queue-length">
        <span class="label">waiting items:</span>
        <span class="value" v-text="status.queueLength" />
      </p>
      <table class="queue-status-table">
        <tr>
          <th></th>
          <th>PROCESSED</th>
          <th>IN CACHE</th>
          <th>FETCHED</th>
          <th>ERRORED</th>
          <th>CHANGED</th>
          <th>ADDED</th>
        </tr>
        <tr>
          <td>last minute:</td>
          <td v-text="f(status.lastMinute.processed)"></td>
          <td v-text="f(status.lastMinute.validCache)"></td>
          <td v-text="f(status.lastMinute.updatedCache)"></td>
          <td v-text="f(status.lastMinute.errored)"></td>
          <td v-text="f(status.lastMinute.fileChanged)"></td>
          <td v-text="f(status.lastMinute.fileAdded)"></td>
        </tr>
        <tr>
          <td>last hour:</td>
          <td v-text="f(status.lastHour.processed)"></td>
          <td v-text="f(status.lastHour.validCache)"></td>
          <td v-text="f(status.lastHour.updatedCache)"></td>
          <td v-text="f(status.lastHour.errored)"></td>
          <td v-text="f(status.lastHour.fileChanged)"></td>
          <td v-text="f(status.lastHour.fileAdded)"></td>
        </tr>
        <tr>
          <td>today:</td>
          <td v-text="f(status.today.processed)"></td>
          <td v-text="f(status.today.validCache)"></td>
          <td v-text="f(status.today.updatedCache)"></td>
          <td v-text="f(status.today.errored)"></td>
          <td v-text="f(status.today.fileChanged)"></td>
          <td v-text="f(status.today.fileAdded)"></td>
        </tr>
        <tr>
          <td>last 24 hours:</td>
          <td v-text="f(status.last24Hours.processed)"></td>
          <td v-text="f(status.last24Hours.validCache)"></td>
          <td v-text="f(status.last24Hours.updatedCache)"></td>
          <td v-text="f(status.last24Hours.errored)"></td>
          <td v-text="f(status.last24Hours.fileChanged)"></td>
          <td v-text="f(status.last24Hours.fileAdded)"></td>
        </tr>
      </table>
      <queue-length-history />
    </collapse>
  </div>
</template>

<script>
export default {
  inject: ['store'],
  created() {
    this.store.queueStatus.subscribe(10000);
  },
  computed: {
    status() {
      return this.store.state.queueStatus;
    }
  },
  methods: {
    f(value) {
      if (typeof value !== 'number') return value;
      return value.toLocaleString('de-DE');
    },
    reloadData() {
      this.store.queueStatus.get();
    }
  }
};
</script>

<style lang="scss">
.queue-status {
  position: relative;
  margin-bottom: 25px;
  p.queue-length {
    .label {
      display: inline-block;
      width: 138px;
    }
    .value {
      display: inline-block;
      width: 130px;
      text-align: right;
      font-weight: bold;
      color: $accent;
    }
  }
  .queue-status-table {
    th {
      width: 143px;
      padding-left: 20px;
      text-align: right;
    }
    th:nth-child(1) {
      padding-left: 0;
      width: 120px;
    }
    td {
      text-align: right;
      font-weight: bold;
      color: $accent;
    }
    td:nth-child(1) {
      text-align: left;
      font-weight: normal;
      color: $textDefault;
    }
  }
}
</style>
