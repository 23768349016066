<template>
  <div class="app-injest">
    <h2>Ingest Url</h2>
    <div><input v-model="url" type="text" /></div>
    <div><button @click="submit">submit</button></div>
    <div v-show="showMessage === 'success'" class="success">
      Successfully injested url.
    </div>
    <div v-show="showMessage === 'error'" class="error">
      Error: {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { submitInjestUrl } from '../lib/injest-url';
export default {
  data() {
    return {
      url: '',
      errorMessage: '',
      showMessage: undefined
    };
  },
  methods: {
    async submit() {
      try {
        const result = await submitInjestUrl(this.url);
        if (result === 'ok') {
          this.showMessage = 'success';
          setTimeout(() => {
            this.clearMessage();
            this.url = '';
          }, 2000);
        } else {
          throw Error(result || 'no response');
        }
      } catch (error) {
        this.errorMessage = error;
        this.showMessage = 'error';
        setTimeout(this.clearMessage, 2000);
      }
    },
    clearMessage() {
      this.errorMessage = '';
      this.showMessage = '';
    }
  }
};
</script>

<style lang="scss">
.app-injest {
  margin: 25px 0;
  display: flex;

  h2 {
    margin: 0;
    width: 166px;
  }
  input {
    margin-top: 3px;
    padding: 3px;
    width: 620px;
  }
  button {
    margin-top: 3px;
    margin-left: 20px;
  }
  .success {
    color: green;
    margin: 5px 0 0 20px;
  }
  .error {
    color: red;
    margin: 5px 0 0 20px;
  }
}
</style>
