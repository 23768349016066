const BACKEND_URL = {
  local: 'http://localhost:8095',
  development: 'https://development.apollo.sqrt-5041.de',
  staging: 'https://staging.apollo.sqrt-5041.de',
  production: 'https://apollo.sqrt-5041.de'
};

export function getBaseUrl() {
  // const normalizedEnv = 'production';
  const normalizedEnv = (
    process.env.VUE_APP_ENVIRONMENT || 'local'
  ).toLowerCase();
  return BACKEND_URL[normalizedEnv];
}

const wait = msec => new Promise(resolve => setTimeout(resolve, msec));

export async function deliverMock(moduleName, mockResponse) {
  await wait(50);
  console.log(`::: ${moduleName} got mock response`);
  return mockResponse;
}
