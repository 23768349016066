<template>
  <div class="latest-items">
    <collapse>
      <template v-slot:title>
        <h2>Latest Urls</h2>
      </template>
      <o-tabs :vertical="true" style="margin-left: 0">
        <template
          v-for="topic in [
            'processed',
            'validCache',
            'updatedCache',
            'errored',
            'fileChanged',
            'fileAdded'
          ]"
          :key="topic"
        >
          <o-tab-item>
            <template v-slot:header>
              <span>{{ topicNames[topic] }}</span>
            </template>
            <table>
              <tr>
                <th>URL</th>
                <th></th>
                <th>TIME</th>
                <th>DETAIL</th>
              </tr>
              <tr v-for="item in store.state.latestItems[topic]" :key="item">
                <td>
                  <div class="url-field">
                    <div
                      class="url"
                      @click="toggleRow(index)"
                      v-text="item.url"
                    />
                  </div>
                </td>
                <td>
                  <mdicon
                    class="copy"
                    @click="copyLink(item.url)"
                    size="16"
                    name="content-copy"
                  />
                  <a target="_playout" :href="item.url">
                    <mdicon size="18" name="play-box-outline" />
                  </a>
                </td>
                <td
                  class="detail"
                  v-text="item.added.substring(0, 19).replace('T', ' ')"
                ></td>
                <td class="detail" v-text="item.detail"></td>
              </tr>
            </table>
          </o-tab-item>
        </template>
      </o-tabs>
    </collapse>
  </div>
</template>

<script>
export default {
  inject: ['store'],
  data() {
    return {
      topicNames: {
        processed: 'processed',
        validCache: 'in cache',
        updatedCache: 'fetched',
        errored: 'errored',
        fileChanged: 'changed',
        fileAdded: 'added'
      }
    };
  },
  computed: {
    processed() {
      return this.store.state.latestItems.processed;
    },
    evaluated() {
      return this.store.state.latestItems.evaluated;
    },
    errored() {
      return this.store.state.latestItems.errored;
    },
    added() {
      return this.store.state.latestItems.added;
    }
  },
  created() {
    this.store.latestItems.subscribe(10000);
  },
  methods: {
    copyLink(url) {
      navigator.clipboard.writeText(url);
    }
  }
};
</script>

<style lang="scss">
.latest-items {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  .o-tabs__nav {
    width: 166px;
    margin-top: 26px;

    .o-tabs__nav-item-wrapper {
      text-align: left;
      justify-content: left;

      .o-tabs__nav-item {
        border: none;
        text-align: left;
        justify-content: left;
        font-size: inherit;
        padding: 2px 0 1px 0;
      }
      .o-tabs__nav-item-default--active {
        color: inherit;
        font-weight: bold;
      }
    }
  }

  table {
    th,
    td {
      text-align: left;
      padding: 0 0 0 20px;
      white-space: nowrap;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 0;
    }
    td.detail {
      font-size: 12px;
    }
    .copy {
      cursor: pointer;
    }
    a {
      margin-left: 5px;
      color: #ff0031;
      font-weight: bold;
      text-decoration: none;
    }
    .url-field {
      display: flex;
      width: 800px;
      .url {
        flex-grow: 1;
        margin-right: 10px;
        cursor: pointer;
        max-width: 780px;
        overflow-x: auto;
      }
      a {
        margin-left: 3px;
        color: #ff0031;
      }
    }
  }
}
</style>
