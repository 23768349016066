import { getBaseUrl, deliverMock } from './util.js';
import mock from './mock/file-request-history.json';

const IS_MOCK = false;

export async function get(url) {
  if (IS_MOCK) {
    return deliverMock('file-request-history', mock);
  }
  const baseUrl = getBaseUrl();
  const query = `url=${encodeURIComponent(url)}`;
  return fetch(`${baseUrl}/file-request-history?${query}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(data => data.json());
}
