<template>
  <div class="ap-collapse">
    <div @click="toggle" class="title-wrapper">
      <slot name="title" />
      <div
        class="control"
        :style="{
          left: controlPosition.split(' ')[0],
          top: controlPosition.split(' ')[1]
        }"
      >
        <mdicon
          v-show="hasIcons"
          :size="iconSize"
          :name="isCollapsed ? collapsedIcon : openIcon"
        />
      </div>
    </div>
    <div
      class="content-wrapper"
      v-show="!isCollapsed"
      v-bind:style="{ paddingLeft: `${indent}px` }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    indent: {
      type: String,
      default: '0'
    },
    collapsedIcon: {
      type: String,
      default: 'chevron-right'
    },
    hasIcons: {
      type: Boolean,
      default: false
    },
    openIcon: {
      type: String,
      default: 'chevron-down'
    },
    iconSize: {
      type: String,
      default: '24'
    },
    controlPosition: {
      type: String,
      default: '-22px 4px'
    }
  },
  data() {
    return {
      isCollapsed: false
    };
  },
  created() {
    this.isCollapsed = this.$props.collapsed;
  },
  emits: ['collapse'],
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('collapse', this.isCollapsed);
    },
    expand() {
      this.isCollapsed = false;
    },
    collapse() {
      this.isCollapsed = true;
    }
  }
};
</script>

<style lang="scss">
.ap-collapse {
  position: relative;
  .title-wrapper {
    position: relative;
    cursor: pointer;
  }
  .control {
    position: absolute;
    top: 0;
    left: -20px;
  }
}
</style>
