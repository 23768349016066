<template>
  <div class="potential-duplicates" v-show="problematic.length">
    <div class="title" @click="isVisible = true">
      potential duplicates ({{ problematic.length }})
    </div>

    <div class="popup" v-show="isVisible">
      <div class="closer" @click="isVisible = false">
        <mdicon name="close" />
      </div>
      <div class="inner">
        <div class="scroll">
          <div style="width: 900px">
            <h2 style="margin-top: 0">Duplicate Detection</h2>
            <p>
              Equality is computed by <i>loudness fingerprinting</i> (same sound
              ≙ same ad). Files having the same fingerprint and delivered within
              the same creatives are usually only variants on playback quality,
              so unproblematic.
            </p>
            <p style="margin-bottom: 0">
              Hence, potentially problematic duplicates are considered as:
            </p>
            <ul style="margin-top: 8px">
              <li>having the same fingerprint</li>
              <li>being delivered in different creatives</li>
            </ul>
          </div>
          <h3 style="margin: 30px 0 10px 0">
            List of potentially problematic duplicates
          </h3>
          <table>
            <tr style="font-weight: bold">
              <td colspan="2">URL</td>
              <td>ADDED</td>
              <td>CAMPAIGN(S)</td>
            </tr>
            <template v-for="[fp, entries] in problematic" v-bind:key="fp">
              <tr class="fp-header">
                <td
                  style="font-weight: bold"
                  colspan="2"
                  v-text="`${fp.substring(0, 16)}:`"
                />
                <td
                  class="show-in-file-search"
                  colspan="2"
                  @click="showInFileSearch(fp)"
                >
                  show in files search
                </td>
              </tr>
              <tr v-for="entry in entries" v-bind:key="entry">
                <td>
                  <div class="url-max-width" v-text="entry.url" />
                </td>
                <td>
                  <a :href="entry.url" target="_playout" style="color: #ff0031">
                    <mdicon size="18" name="play-box-outline" />
                  </a>
                </td>
                <td v-text="entry.inserted" />
                <td v-text="entry.lineItems?.join(', ')"></td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function arrayEqual(a = [], b = []) {
  return a.length === b.length && a.every(i => b.includes(i));
}

export default {
  props: {
    filesInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isVisible: false,
      eventListenerEscape: e => {
        if (e.keyCode === 27) {
          this.isVisible = false;
        }
      }
    };
  },
  watch: {
    isVisible(value) {
      if (value) {
        document.querySelector('body').classList.add('noscroll');
        document.addEventListener('keyup', this.eventListenerEscape);
      } else {
        document.querySelector('body').classList.remove('noscroll');
        document.removeEventListener('keyup', this.eventListenerEscape);
      }
    }
  },
  computed: {
    problematic() {
      const groupedByFingerprint = this.$props.filesInfo.reduce((acc, line) => {
        const {
          url,
          lineItems,
          inserted,
          fileInfo: { loudness: { fingerprint: fp } = {} }
        } = line;
        if (!fp) return acc;
        acc[fp] = acc[fp] || [];
        acc[fp].push({ url, inserted: inserted?.substring(0, 10), lineItems });
        return acc;
      }, {});

      return Object.entries(groupedByFingerprint).filter(([, entries]) => {
        if (entries.length < 2) return false;
        const allLineItems = [
          ...new Set(entries.map(entry => entry.lineItems).flat())
        ];
        return !entries.every(entry =>
          arrayEqual(entry.lineItems || [], allLineItems)
        );
      });
    }
  },
  methods: {
    showInFileSearch(fingerprint) {
      this.isVisible = false;
      this.$emit('set-search-value', fingerprint);
    }
  }
};
</script>

<style lang="scss">
body.noscroll {
  overflow: hidden;
}
.potential-duplicates {
  position: absolute;
  top: 0;
  left: 650px;
  width: 0;
  .title {
    position: absolute;
    top: 0;
    left: 0px;
    width: 200px;
    text-align: right;
    font-weight: bold;
    cursor: pointer;
  }
  .popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    backdrop-filter: blur(2px);
    z-index: 1000;
    .closer {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .inner {
      padding: 20px;
      background-image: url('~@/assets/background1.png');
      background-repeat: repeat;
      border: 1px solid $accent;
      border-radius: 4px;
      height: 100%;
      .scroll {
        max-height: 100%;
        overflow-y: auto;

        .show-in-file-search {
          cursor: pointer;
          color: $accent;
        }
        tr.fp-header {
          > td {
            padding-top: 12px;
          }
        }
        td {
          padding-left: 0;
          padding-right: 10px;
          white-space: nowrap;

          div.url-max-width {
            max-width: 800px;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
