<template>
  <div class="login">
    <div class="title">Please log in:</div>
    <hr />
    Password: <input v-model="password" type="password" />
    <button @click="login">Submit</button>
    <p class="complaint" v-text="complaint"></p>
    <p>
      You can find the password in
      <a
        target="_blank"
        href="https://confluence.p7s1-tech.com/display/ADTECHWIKI/Credentials"
        >Confluence</a
      >.
    </p>
  </div>
</template>

<script>
const hashedTruth = 'ywQr2we2Q7Am8cLkL+LVdyB6PwAcAKz3F94Hz913/v0=';

export default {
  emits: ['logged-in'],
  data() {
    return {
      password: '',
      complaint: ''
    };
  },
  created() {
    const saved = this.readFromLocal();
    if (saved === hashedTruth) {
      this.$emit('logged-in');
    }
  },
  methods: {
    async login() {
      const hashed = await this.hash(this.password);
      if (hashed !== hashedTruth) {
        this.complaint = 'Wrong Password.';
        return;
      }
      this.saveToLocal(hashed);
      this.$emit('logged-in');
    },
    async hash(input) {
      const buffer = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(input)
      );
      return btoa(
        Array.prototype.map
          .call(new Uint8Array(buffer), ch => String.fromCharCode(ch))
          .join('')
      );
    },
    readFromLocal() {
      return localStorage.getItem('apolloHash');
    },
    saveToLocal(value) {
      localStorage.setItem('apolloHash', value);
    }
  }
};
</script>

<style lang="scss">
.login {
  margin: 100px auto 0 auto;
  padding: 15px;
  width: 400px;
  border: 1px solid $accent;
  border-radius: 4px;

  .title {
    font-weight: bold;
  }

  hr {
    margin: 10px -15px 10px -15px;
    background-color: $accent;
  }
  button {
    margin-left: 20px;
  }
  .complaint {
    color: red;
  }
  a {
    color: $accent;
    text-decoration: none;
  }
}
</style>
