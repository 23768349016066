import { reactive, readonly } from 'vue';
import * as libLatestItems from '../lib/latest-items';
let timeout;
let intervalId;

const state = reactive({
  processed: [],
  evaluated: [],
  errored: [],
  added: []
});

const methods = {
  get: (fromSubscribe = false) => {
    if (!fromSubscribe && intervalId) {
      methods.subscribe(timeout);
      return;
    }
    libLatestItems.get().then(result => {
      Object.assign(state, result);
    });
  },
  subscribe: (interval = 60000) => {
    if (intervalId) {
      clearTimeout(intervalId);
      intervalId = undefined;
    }
    timeout = interval;
    methods.get(true);
    intervalId = setInterval(() => {
      methods.get(true);
    }, interval);
  }
};

export default { state: readonly(state), methods };
