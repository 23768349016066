<template>
  <div class="reload-button" @click="onClick">
    <mdicon size="24" name="refresh" />
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss">
.reload-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  text-align: center;
  color: $accent;
  font-weight: normal;
  font-size: 30px;
  line-height: 0.85em;
  border: 1px solid $accent;
  border-radius: 4px;
  cursor: pointer;
}
</style>
