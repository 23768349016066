<template>
  <div class="app-wrapper">
    <app-header />
    <hr />
    <login v-if="!isLoggedIn" @logged-in="loggedIn" />
    <template v-if="isLoggedIn">
      <queue-status />
      <hr />
      <latest-items />
      <hr />
      <injest />
      <hr />
      <file-search />
    </template>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false
    };
  },
  methods: {
    loggedIn() {
      this.isLoggedIn = true;
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
  background-image: url('~@/assets/background1.png');
  background-repeat: repeat;
}
.app-wrapper {
  min-width: 1038px;
  margin: 0 100px;

  hr {
    background-color: #ccc;
    height: 1px;
    border: none;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textDefault;

  button {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
}
</style>
