import { reactive, readonly } from 'vue';
import * as libFilesRequestHistory from '../lib/file-request-history.js';

let timeout;
let intervalId;

const state = reactive({
  history: {}
});

const methods = {
  get: (url, fromSubscribe = false) => {
    if (!fromSubscribe && intervalId) {
      methods.subscribe(url, timeout);
      return;
    }
    libFilesRequestHistory.get(url).then(result => {
      state.history[url] = result;
    });
  },
  subscribe: (url, interval = 60000) => {
    if (intervalId) {
      clearTimeout(intervalId);
      intervalId = undefined;
    }
    timeout = interval;
    methods.get(url, true);
    intervalId = setInterval(() => {
      methods.get(url, true);
    }, interval);
  }
};

export default { state: readonly(state), methods };
